<template>
  <div
    class="newcharg"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="top">
      <div class="topline"></div>
      <div class="topmsg">
        <div class="plateno">
          <div class="icon"><img src="../../../assets/iconcar.png" /></div>
          <div class="num">{{ plateNo }}</div>
        </div>
        <div class="cash">
          <div class="money">
            <i style="font-size: 12px">￥</i>
            {{ paymentOrderDate.amount }}
          </div>
          <div class="free-text" v-if="paymentOrderDate.shouldPay == 0 ? true : false">
            当前处于免费时段，可以直接出场
          </div>
          <div class="parkname">{{ parkingName ? parkingName : "停车场" }}</div>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="top-foot">
        <div class="leftpoint"></div>
        <div class="line"></div>
        <div class="rightpoint"></div>
      </div>
      <div class="banner-msg">
        <div class="message">
          <div style="font-weight: bold; color: black">费用详情</div>
          <div></div>
        </div>
        <!-- <div class="message" v-if="type == 5">
          <div>停车时长</div>
          <div>{{ paymentOrderDate.dataItems[0].useTime | formatMin }}</div>
        </div> -->
        <div class="message">
          <div>停车时长</div>
          <div>{{ paymentOrderDate.parkTime }}</div>
        </div>
        <div class="message">
          <div>驶入时间</div>
          <div>{{ paymentOrderDate.parkIn }}</div>
        </div>
        <div class="message">
          <div>驶出时间</div>
          <div>{{ paymentOrderDate.parkOut }}</div>
        </div>
        <div class="message">
          <div>应付金额</div>
          <div style="color: #2370ff">￥{{ paymentOrderDate.shouldPay }}</div>
        </div>
        <div class="message">
          <div>优惠金额</div>
          <div style="color: #2370ff">-￥{{ paymentOrderDate.discount }}</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="top-foot">
        <div class="leftpoint"></div>
        <div class="line"></div>
        <div class="rightpoint"></div>
      </div>
      <div class="foot-sumbmit">
        <button
          class="sumbmit-btn"
          @click="payHandle"
          v-preventReClick="3000"
          :disabled="paymentOrderDate.shouldPay == 0 ? true : false"
        >
          立即付款
        </button>
        <div class="remark">
          如有疑问，您可按车场设备对接按钮呼叫人工服务或直接<span style="color: #2370ff" @click="handleCall">
            呼叫客服
          </span>
        </div>
      </div>
    </div>
    <div class="foot-line"></div>
    <div class="coupon">
      <van-cell
        title="优惠券"
        is-link
        :value="
          checkCouponList.type === 1
            ? '减金额'
            : checkCouponList.type === 2
            ? '减时长'
            : checkCouponList.type === 3
            ? '百分比'
            : checkCouponList.type === 4
            ? '全免券'
            : checkCouponList.type === 5
            ? '全额免'
            : checkCouponList.type == 6
            ? '折扣券'
            : '无'
        "
        style="padding: 10px"
        @click="couponshow = true"
      >
        <template #title>
          <span class="custom-title">优惠券</span>
          <van-tag plain type="danger" style="margin-left: 5px" v-if="checkCouponList.isOptimal ? true : false">
            已选择推荐优惠
          </van-tag>
        </template>
      </van-cell>
    </div>
    <van-popup v-model="couponshow" closeable round position="bottom" :style="{ height: '60%' }" class="coupon-box">
      <div v-if="allCouponList.length">
        <div class="coupon-title">优惠券</div>
        <van-divider :hairline="false" />
        <div style="font-size: 15px; color: #333333; margin-bottom: 10px">
          您已选择{{ ticketNo !== "" ? 1 : 0 }}张优惠券
        </div>
        <van-radio-group v-model="ticketNo" style="margin: 10px 0; font-size: 15px">
          <van-radio name="" checked-color="#FFA763">不使用优惠券</van-radio>
        </van-radio-group>
        <div class="coupontype-box">
          <div class="coupontype" v-for="(item, index) in allCouponList" :key="index">
            <div class="left">
              <div style="color: #ffffff; font-size: 30px; font-weight: 400">
                <i style="font-size: 12px" v-if="item.type === 1">￥</i>{{ item.couponValue }}
                <i style="font-size: 12px" v-if="item.type === 6">折</i>
                <i style="font-size: 12px" v-if="item.type === 2">分钟</i>
              </div>
              <div class="couponname">
                {{
                  item.type === 1
                    ? "减金额"
                    : item.type === 2
                    ? "减时长"
                    : item.type === 3
                    ? "百分比"
                    : item.type === 4
                    ? "全免券"
                    : item.type === 5
                    ? "全额免"
                    : "折扣券"
                }}
              </div>
            </div>
            <div class="right">
              <div class="top-cotent">
                <div style="font-size: 15px; font-weight: 400; letter-spacing: 1px; color: #333333">
                  {{ item.couponName }}
                </div>
                <div class="rule" @click="handleRule(item)">
                  规则
                  <div class="el-icon-arrow-right"></div>
                </div>
              </div>
              <div class="checkbox" style="margin-top: 5px; float: right">
                <van-radio-group v-model="ticketNo">
                  <van-radio :name="item.id" checked-color="#FFA763"></van-radio>
                </van-radio-group>
              </div>
              <div class="usetime"></div>
              <div class="usetime2">有效期至:{{ item.endTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <van-empty v-else description="暂无可用优惠券" />
      <div class="coupon-define" @click="couponsumbit">确定</div>
    </van-popup>
  </div>
</template>

<script>
import _ from "lodash";
import { Dialog } from "vant";

export default {
  name: "newcharging",
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  // 过滤器
  filters: {
    // 秒过滤器
    formatSeconds(value) {
      let result = parseInt(value);
      // 天
      let y = Math.floor(result / 86400) < 10 ? "0" + Math.floor(result / 86400) : Math.floor(result / 86400);
      // 时
      let h =
        Math.floor((result / 3600) % 24) < 10
          ? "0" + Math.floor((result / 3600) % 24)
          : Math.floor((result / 3600) % 24);
      // 分
      let m =
        Math.floor((result / 60) % 60) < 10 ? "0" + Math.floor((result / 60) % 60) : Math.floor((result / 60) % 60);
      // 秒
      let s = Math.floor(result % 60) < 10 ? "0" + Math.floor(result % 60) : Math.floor(result % 60);

      let res = "";
      if (y !== "00") res += `${y}天`;

      if (h !== "00") res += `${h}时`;

      if (m !== "00") res += `${m}分`;

      res += `${s}秒`;

      return res;
    },
    // 分钟过滤器
    formatMin(val) {
      let result = parseInt(val);
      // 天
      let y = Math.floor(result / 60 / 24) < 10 ? "0" + Math.floor(result / 60 / 24) : Math.floor(result / 60 / 24);
      // 时
      let h =
        Math.floor((result / 60) % 24) < 10 ? "0" + Math.floor((result / 60) % 24) : Math.floor((result / 60) % 24);
      // 分
      let m = Math.floor(result % 60) < 10 ? "0" + Math.floor(result % 60) : Math.floor(result % 60);

      let res = "";
      if (y !== "00") res += `${y}天`;

      if (h !== "00") res += `${h}时`;

      if (m !== "00") res += `${m}分`;

      return res;
    },
  },
  data() {
    return {
      loading: false,
      paymentOrderDate: {},
      queryDate: {},
      parkingName: localStorage.getItem("parkingName"),
      plateNo: localStorage.getItem("plateNo"),
      type: localStorage.getItem("type") * 1,
      appId: localStorage.getItem("appId"),
      itemId: localStorage.getItem("itemId"),
      // 优惠券
      ticketNo: null,
      checkCouponList: {},
      couponshow: false,
      allCouponList: [],
      domain: process.env.VUE_APP_DOMAIN,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleCall() {
      this.$router.push({
        path: "/outpark/video",
        query:{type:2}
      });
    },
    // 优惠券确定
    couponsumbit() {},
    // 列表请求
    getList() {
      let { appId, itemId, type, plateNo, ticketNo } = this;
      if (type == 13) {
        this.queryDate = this.$route.query;
        this.$axios
          .post(`${this.domain}/api/charge/doPkCharge`, {
            appId,
            itemId,
            orderNo: this.queryDate.orderNo,
            chanUuid: this.queryDate.chanUuid,
            payScene: this.queryDate.payScene,
            ticketNo,
          })
          .then((res) => {
            this.paymentOrderDate = res.data.data;
            if (res.data.data.plateType == 3) {
              Dialog.alert({
                message: `当前车辆为月租车，可直接出场`,
                theme: "round-button",
              }).then(() => {
                // on close
              });
            }
          });
      } else if (type == 14) {
        this.queryDate.orderNo = this.$route.params.orderNo;
        this.$axios
          .post(`${this.domain}/api/pay/prePayDel`, {
            appId,
            itemId,
            plateNo,
            payScene: 0,
            ticketNo,
          })
          .then((res) => {
            this.paymentOrderDate = res.data.data;
            this.queryDate.orderNo = res.data.data.orderNo;
            if (res.data.data.plateType == 3) {
              Dialog.alert({
                message: `当前车辆为月租车，可直接出场`,
                theme: "round-button",
              }).then(() => {
                // on close
              });
            }
          })
          .catch((e) => {
            Dialog.alert({
              message: `${res.data.msg}`,
              theme: "round-button",
            }).then(() => {
              // on close
            });
          });
      }
    },
    // 立即付款按钮
    payHandle: _.debounce(function () {
      let openId = localStorage.getItem("openId");
      let userId = localStorage.getItem("alipayuserId");
      if (openId) {
        this.wxSettlement();
      }
      if (userId) {
        this.aliSettlement();
      }
    }, 100),
    // 支付宝结算
    aliSettlement() {
      let { paymentOrderDate, queryDate, appId, itemId, type, plateNo } = this;
      let userId = localStorage.getItem("alipayuserId");
      let optFlag = type == 14 ? true : false;
      let chanUuid = type == 14 ? null : queryDate.chanUuid;
      this.$axios
        .post(`${this.domain}/api/pay/zfbPkPay`, {
          itemId,
          appId,
          userId,
          amount: paymentOrderDate.shouldPay,
          orderNo: queryDate.orderNo,
          plateNo,
          chanUuid,
          optFlag,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.tradePay(`${res.data.trade_no}`);
          } else {
            alert("未找到订单，请重新扫码");
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    // 支付宝收银台
    // 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
    ready(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener("AlipayJSBridgeReady", callback, false);
      }
    },
    tradePay(tradeNO) {
      this.ready(function () {
        // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
        AlipayJSBridge.call(
          "tradePay",
          {
            tradeNO: tradeNO,
          },
          function (data) {
            if (data.resultCode === "9000") {
              AlipayJSBridge.call("closeWebview");
            } else {
              alert("支付失败，请重新扫码");
            }
          }
        );
      });
    },
    // 微信结算
    wxSettlement() {
      let { paymentOrderDate, queryDate, appId, itemId, type, plateNo } = this;
      let openId = localStorage.getItem("openId");
      let optFlag = type == 14 ? true : false;
      let chanUuid = type == 14 ? null : queryDate.chanUuid;
      this.$axios
        .post(`${this.domain}/api/pay/wxPkPay`, {
          itemId,
          appId,
          openId,
          amount: paymentOrderDate.shouldPay,
          orderNo: queryDate.orderNo,
          plateNo,
          chanUuid,
          optFlag,
        })
        .then((res) => {
          let data = res.data.data;
          this.weixinPay(data);
        })
        .catch((error) => {
          alert(error);
        });
    },
    // 微信收银台
    //解决微信内置对象报错
    weixinPay(data) {
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener("WeixinJSBridgeReady", that.onBridgeReady(data), false);
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady(data));
          document.attachEvent("onWeixinJSBridgeReady", that.onBridgeReady(data));
        }
      } else {
        that.onBridgeReady(data);
      }
    },
    // 微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
    onBridgeReady(data) {
      WeixinJSBridge.invoke("getBrandWCPayRequest", data, (res) => {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          this.$router.push("/inpark/success");
        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
          alert("支付已取消，请重新扫码");
        } else {
          this.$router.push("/inpark/fail");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}
.newcharg {
  padding: 15px;
  min-height: 100vh;
  background-color: rgb(248, 248, 248);

  .top {
    width: 345px;
    .topline {
      height: 20px;
      background-image: url("../../../assets/topline.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .topmsg {
      box-sizing: border-box;
      padding: 5px 15px 15px;
      height: 116px;
      background-color: #fff;

      .plateno {
        display: flex;
        align-items: center;

        .icon {
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          background-color: #2370ff;
          border-radius: 50%;

          img {
            width: 12px;
            height: 10px;
          }
        }

        .num {
          margin-left: 5px;
          font-size: 18px;
          color: #333333;
        }
      }

      .cash {
        margin-top: 15px;
        text-align: center;

        .money {
          font-size: 18px;
          color: #2370ff;
          font-weight: 700;
          margin-bottom: 5px;
        }

        .free-text {
          font-size: 13px;
          color: #2370ff;
          text-align: center;
        }

        .parkname {
          font-size: 14px;
          color: #a3a3a3;
        }
      }
    }
  }

  .banner {
    .top-foot {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      height: 16px;
      background-color: #fff;

      .leftpoint {
        position: absolute;
        left: 0;
        transform: translate(-50%, 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #f8f8f8;
      }

      .line {
        margin: 0 auto;
        width: 300px;
        border: 1px dashed #e9e7e7;
      }

      .rightpoint {
        position: absolute;
        right: 0;
        transform: translate(50%, 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #f8f8f8;
      }
    }

    .banner-msg {
      box-sizing: border-box;
      padding: 17px 15px;
      height: 210px;
      background-color: #fff;

      .message {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        color: #a3a3a3;
      }
    }
  }

  .footer {
    .top-foot {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      height: 16px;
      background-color: #fff;

      .leftpoint {
        position: absolute;
        left: 0;
        transform: translate(-50%, 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #f8f8f8;
      }

      .line {
        margin: 0 auto;
        width: 300px;
        border: 1px dashed #e9e7e7;
      }

      .rightpoint {
        position: absolute;
        right: 0;
        transform: translate(50%, 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #f8f8f8;
      }
    }

    .foot-sumbmit {
      box-sizing: border-box;
      padding: 20px 15px 0 15px;
      height: 120px;
      background-color: #fff;

      .sumbmit-btn {
        display: block;
        outline: none;
        border: none;
        width: 315px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        color: #fff;
        font-size: 15px;
        background-color: #2370ff;
        border-radius: 22px;
        box-shadow: 0px 2px 10px rgba(35, 112, 255, 0.4);
      }
    }

    .remark {
      margin-top: 15px;
      color: #a3a3a3;
      font-size: 15px;
      text-align: center;
    }
  }

  .foot-line {
    position: relative;
    height: 20px;
    background-image: url("../../../assets/footline.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .coupon {
    z-index: 99;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .coupon-box {
    box-sizing: border-box;
    padding: 13px 10px 50px 10px;
    overflow-y: hidden;

    .coupon-title {
      display: flex;
      justify-content: center;
      font-size: 18px;
    }
    .coupontype-box {
      box-sizing: border-box;
      height: 366px;
      padding-bottom: 100px;
      overflow-y: scroll;
      .coupontype {
        display: flex;
        position: relative;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);

        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 80px;
          background: linear-gradient(90deg, rgba(254, 213, 134, 1) 1.251%, rgba(255, 157, 77, 1) 100%);
          border-radius: 5px 0px 0px 5px;

          .couponname {
            width: 48px;
            height: 20px;
            line-height: 20px;
            background: rgb(250, 160, 97);
            border-radius: 3px;
            padding: 0px 6px 0px 6px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
          }
        }

        .right {
          position: relative;
          box-sizing: border-box;
          padding: 10px;
          width: 265px;
          height: 80px;
          background: rgb(255, 255, 255);
          border-radius: 0px 5px 5px 0px;
          line-height: 20px;

          .top-cotent {
            display: flex;
            justify-content: space-between;
            .rule {
              display: flex;
              align-items: center;
              font-size: 13px;
              color: #a3a3a3;

              .ruleimg {
                margin-left: 5px;
                width: 5px;
                height: 9px;
                background-image: url("../../../assets/coupon-.png");
                background-repeat: repeat;
                background-position: none;
              }
            }
          }

          .checkbox {
            display: flex;
            justify-content: right;
          }

          .usetime {
            position: absolute;
            bottom: 20px;
            font-size: 12px;
            color: #a3a3a3;
          }

          .usetime2 {
            position: absolute;
            bottom: 5px;
            font-size: 12px;
            color: #a3a3a3;
          }
        }
      }
    }

    .coupon-define {
      position: fixed;
      bottom: 10px;
      left: 10px;
      right: 10px;
      background: linear-gradient(180deg, rgba(255, 167, 99, 1), rgba(250, 114, 12, 1) 100%);
      /* 橙色阴影 */
      box-shadow: 0px 2px 10px rgba(250, 114, 12, 0.4);
      font-size: 15px;
      color: #fff;
      cursor: pointer;
      border-radius: 22px;
      height: 44px;
      line-height: 44px;
      font-size: 15px;
      text-align: center;
    }
  }
}
</style>
